import React, { useState } from "react";
import { getTextColor, hexToRgb } from "utils/colors";
import { get } from "lodash";
import { RuleList } from "components/roster/rule-list";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { StyledTableRow } from "components/styled-table";

export const ArmorList = (props) => {
  const { armors, faction, data, toggler, rules, twoColumns = true } = props;
  const { color: factionColor, secondary_color: factionSecondaryColor } =
    faction;
  const textColor = factionColor
    ? getTextColor(hexToRgb(factionColor))
    : "white";
  const textColorSecondary = factionSecondaryColor
    ? getTextColor(hexToRgb(factionSecondaryColor))
    : "white";
  const borderColor = textColor !== "white" ? textColor : factionColor;
  const btnStyle = { borderColor };
  const thStyle = {
    backgroundColor: factionSecondaryColor || factionColor,
    color: factionSecondaryColor ? textColorSecondary : textColor,
  };
  const [showArmors, setShowArmors] = useState(false);
  const renderRules = (rules) => {
    if (!rules || !rules.length) {
      return;
    }
    return (
      <div>
        <RuleList twoColumn={twoColumns} faction={faction} rules={rules} />
      </div>
    );
  };
  return (
    <div>
      {!!toggler && (
        <div align="center">
          <div style={{ marginBottom: "0.5rem" }}>
            <Button
              sx={{ color: "inherit", textTransform: "none" }}
              size="small"
              fullWidth
              variant="outlined"
              style={btnStyle}
              onClick={() => setShowArmors(!showArmors)}
            >
              {showArmors ? "Hide" : "Armors"}
            </Button>
          </div>
        </div>
      )}
      <Collapse in={!toggler || showArmors}>
        <TableContainer sx={{ borderRadius: '2px', mb: 1 }}>
          <Table size="small" style={{ borderColor: borderColor }}>
            <TableHead>
              <StyledTableRow style={thStyle}>
                <TableCell>{"Armor"}</TableCell>
                <TableCell align="center">{"Defense Bonus"}</TableCell>
                <TableCell align="center">{"Survival Bonus"}</TableCell>
                <TableCell align="center">{"Mobility Actions"}</TableCell>
                <TableCell>{"Special"}</TableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {armors.map((armor) => {
                return (
                  <StyledTableRow>
                    <TableCell>{armor.name}</TableCell>
                    <TableCell align="center">
                      {armor.defense_bonus ? armor.defense_bonus : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {armor.survival_bonus ? armor.survival_bonus : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {armor.mobility_actions ? armor.mobility_actions : "-"}
                    </TableCell>
                    <TableCell>
                      {get(armor, "rules", [])
                        .map((rule) => {
                          const ruleData = data.getRule(
                            rule.id || rule,
                            faction
                          );
                          return ruleData.inputs
                            ? `${ruleData.name}(${ruleData.inputs
                                .map((input) => rule[input])
                                .join(", ")})`
                            : ruleData.name;
                        })
                        .join(", ") || "-"}
                    </TableCell>
                    
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {!!(rules && rules.length) && <>{renderRules(rules)}</>}
      </Collapse>
    </div>
  );
};
